@import url("swiper/css");
@import url("swiper/css/effect-creative");
@import url("swiper/css/navigation");
@import url("swiper/css/pagination");
@import url("swiper/css/scrollbar");
@import url("responsive-dynamic.css");

@font-face {
  font-family: "BentonSans Bold";
  src: url("/fonts/BentonSans Bold.otf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans";
  src: url("/fonts/BentonSans Regular.otf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MercuryTextG1";
  src: url("/fonts/MercuryTextG1 Regular.otf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "MercuryDisplay";
  src: url("/fonts/MercuryDisplayRoman.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "BentonSans RegularItalic";
  src: url("/fonts/BentonSans RegularItalic.otf");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans Medium";
  src: url("/fonts/BentonSans Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans Thin";
  src: url("/fonts/BentonSans Thin.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "BentonSans Light";
  src: url("/fonts/BentonSans Light.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

html,
body {
  min-height: 100vh;
  font-family: "BentonSans", sans-serif;
  font-weight: 200;
  overflow: hidden;
  color: rgb(10, 10, 10);
}

@media screen and (max-width: 37.6em) {
  body {
    overflow-y: scroll;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

main {
  width: 100%;
  height: 100%;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.dyamicPageTab .Mui-selected {
  color: skyblue !important;
}

.dyamicPageTab .MuiTabs-indicator {
  background-color: skyblue !important;
  top: 0 !important;
  bottom: auto !important;
}

.dynamicnavbarTabCenter {
  justify-content: center;
}

.dark .gdQSWX {
  background-color: #000;
}

.page-content-wrapper .sc-bjjCGC.oEMwV .sc-gCLdxd.container {
  /* max-width: 1172px; */
  max-width: 100% !important;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  h3,
.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .typo-subtitle.my-8,
.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .typo-body {
  max-width: 1172px;
  margin: 0 auto;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .sc-dNezTh.jMXwAf.sc-eTgnfb.dUVijN {
  max-width: 1172px;
  margin: 0 auto;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .sc-dNezTh.jMXwAf.sc-fbHdRr.fJQeQi {
  max-width: 1172px;
  margin: 0 auto;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .sc-dNezTh.jMXwAf.sc-iJRSss.kbQYLT {
  position: relative;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .sc-dNezTh.jMXwAf.sc-iJRSss.kbQYLT::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.0001) 46.5%,
    #ffffff 100%
  );
  mix-blend-mode: screen;
  opacity: 0.15;
}

.page-content-wrapper
  .sc-bjjCGC.oEMwV
  .sc-gCLdxd.container
  .sc-dNezTh.jMXwAf
  .sc-dNezTh.jMXwAf.sc-iJRSss.kbQYLT
  .sc-fVLGaz.row {
  max-width: 1172px;
  margin: 0 auto;
}

.gradentOverlay {
  position: relative;
  z-index: 1;
}

.gradentOverlay::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.0001) 46.5%,
    #ffffff 100%
  );
  mix-blend-mode: screen;
  opacity: 0.15;
  z-index: -1;
}

.page-content-wrapper.home-baner .container {
  /* max-width: 1172px; */
  max-width: 100%;
  padding: 0 !important;
}

.MuiContainer-root {
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

@media screen and (max-width: 767px) {
  .MuiContainer-root {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
/* .Upcoming-Sales-block .MuiContainer-root .jMXwAf{padding-top: 0 !important;} */
.menunav {
  display: flex;
}

.menunav > li {
  display: flex;
  color: #000;
  position: relative;
  margin-left: 15px;
}

.menunav li .navLink {
  color: #000;
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 15px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menunav li ol {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  /* transition: all 0.3s; */
  transform: scaleY(0);
  transform-origin: top left;
  list-style-type: none;
  background: var(--bid-text-status);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  overflow: hidden auto;
  min-width: 150px;
  padding: 0 12px;
  max-height: 380px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
@media screen and (min-width: 991px) {
  .menunav li.open:hover ol {
    transform: scaleY(0);
  }
  .show-mobile-view {
    display: none;
  }
}
.menunav li:hover ol {
  transform: scaleY(1);
}
.menunav li:hover .dropdown-icon {
  transform: rotateX(180deg);
}
.menunav li ol li {
  border-top: 1px solid var(--light-black);
  color: var(--black);
  font-family: "MercuryTextG1";
  font-style: normal;
  padding: 11px 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  display: block;
  white-space: nowrap;
  cursor: pointer;
}
.menunav li ol li:first-child {
  border-top: 0px;
}
.menunav li ol li span {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-top: 7px;
  color: var(--gray_black);
  display: block;
}
.menunav li .navLink[aria-controls="fade-menu"] .dropdown-icon {
  transform: rotateX(180deg);
}

.menunav li .navLink .dropdown-icon {
  margin-left: 12px;
  transition: all 0.5s;
}

.homeBanner .sc-hAGLhy.WDVud {
  position: relative;
}

.homeBanner .sc-hAGLhy.WDVud::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  z-index: 1;
}

.homeBanner .typo-h2 {
  position: relative;
  z-index: 2;
  font-family: "MercuryDisplay";
  font-style: normal;
  font-weight: 325;
  font-size: 48px;
  line-height: 58px;
}

.homeBanner .typo-subtitle {
  position: relative;
  z-index: 2;
  font-family: "BentonSans Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  /* identical to box height, or 157% */
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}

.homeBanner .typo-body {
  position: relative;
  z-index: 2;
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* or 147% */

  text-align: center;
  letter-spacing: -0.1px;
}

.homeBanner button {
  position: relative;
  z-index: 2;
  padding: 14px 30px;
  color: #fff;
  width: 187px;
  height: 45px;
  max-height: 45px;
  background: linear-gradient(0deg, rgba(66, 0, 97, 0.4), rgba(66, 0, 97, 0.4)),
    linear-gradient(92.96deg, #b970db 0%, #57ffbc 161.11%);
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "BentonSans Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;

  color: #ffffff;
}

.details .title {
  font-family: "MercuryDisplay";
  font-style: normal;
  font-weight: 325;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height, or 120% */

  letter-spacing: -0.1px;

  color: #ffffff;
}

.details .artist,
.details .year {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 147% */

  letter-spacing: -0.1px;

  color: rgba(255, 255, 255, 0.5);
}

.details .USD-text {
  display: flex !important;
  align-items: center !important;
  margin-top: 25px;
}

.details .eth-price {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 150% */

  letter-spacing: 0.05em;

  color: #ffffff;

  opacity: 0.5;
}

.details .d-flex.align-items-center {
  align-items: center;
}

.btn-primary {
  background: linear-gradient(0deg, rgba(66, 0, 97, 0.4), rgba(66, 0, 97, 0.4)),
    linear-gradient(92.96deg, #b970db 0%, #57ffbc 161.11%) !important;
  border-radius: 5px !important;
  font-family: "BentonSans" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;

  color: #ffffff !important;
}

.section-pd-80 {
  padding: 80px 0;
}

/* // responsive */
@media screen and (max-width: 1599px) {
  .section-pd-80 {
    padding: 70px 0;
  }
}

@media screen and (max-width: 1439px) {
  .section-pd-80 {
    padding: 60px 0;
  }
}

@media screen and (max-width: 1279px) {
  .section-pd-80 {
    padding: 50px 0;
  }
}

@media screen and (max-width: 767px) {
  .section-pd-80 {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .section-pd-80 {
    padding: 40px 0;
  }
}

.section-pd-62 {
  padding: 60px 0;
}

@media screen and (max-width: 1024px) {
  .section-pd-62 {
    padding: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .section-pd-62 {
    padding: 10px 0;
  }
}

.footer-nav a {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #fff !important;
}

.bannerContent .bannerContentRightText {
  position: absolute;
  right: 69px;
  top: 60px;
  max-width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bannerContent .bannerContentRightText h4 {
  font-family: "BentonSans Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */

  text-align: center;
  letter-spacing: -0.1px;

  color: #ffffff;
}

.bannerContentLeftAlignment {
}

.headermenuDropDown .MuiPaper-root {
  background: var(--bid-text-status);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}

.headermenuDropDown .MuiPaper-root .MuiList-root {
  min-width: 150px;
  padding: 0 12px;
  max-height: 380px;
}

.headermenuDropDown .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  border-top: 1px solid var(--ligt_black);
  color: var(--black);
  font-family: "MercuryTextG1";
  font-style: normal;
  padding: 11px 20px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.01em;
  display: block;
}

.headermenuDropDown .MuiPaper-root .MuiList-root .MuiButtonBase-root span {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-top: 7px;
  color: var(--gray_black);
  display: block;
}

/* // responsive  */
@media screen and (max-width: 991px) {
  .headermenuDropDown .MuiPaper-root .MuiList-root .MuiButtonBase-root {
    font-size: 18px;
    line-height: 22px;
    padding: 7px 7px;
  }
  .headermenuDropDown .MuiPaper-root .MuiList-root .MuiButtonBase-root span {
    font-size: 14px;
  }
}
.pagination_main .MuiPaginationItem-root {
  color: #424242;
  font-size: 18px;
  font-weight: 700;
}

.pagination_main .MuiPaginationItem-previousNext {
  border: 1px solid #bdbdbd;
}

.pagination_main .MuiPaginationItem-previousNext svg path {
  fill: #bdbdbd;
}

.pagination_main .MuiPaginationItem-previousNext.Mui-disabled {
  opacity: 1 !important;
}

.pagination_main .MuiPaginationItem-root.Mui-selected {
  color: white !important;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .btn-size {
    /* width: 300px; */
  }
}

.banner {
  background-image: url("/images/Rectangle.png");
  background-size: cover;
  color: white;
  text-align: center;
  padding: 100px 20px;
  background-position: center center;
}

.banner h2 {
  margin-bottom: 32px;
}

.banner p {
  max-width: 618px;
  margin: auto;
  font-size: 15px;
}

.dropdown_wrap_main .MuiFormLabel-root {
  color: #ffffff;
  font-weight: 600;
}

.dropdown_wrap_main .MuiFormLabel-root.Mui-focused {
  color: #ffffff;
  font-weight: 400;
}

.dropdown_wrap_main .MuiInputBase-root {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 28px;
  font-weight: 600;
}

.dropdown_wrap_main .MuiInputBase-root svg path {
  fill: white;
}

.dropdown_wrap_main .MuiInputBase-root.Mui-focused {
  border: none;
}

.dropdown_wrap_main .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.dropdown_wrap_main
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-width: 0px;
  border-radius: 28px;
}

.imageReverseData {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.sliderBtmDots .swiper .swiper-wrapper {
  margin-bottom: 40px;
}
@media screen and (max-width: 991px) {
  .sliderBtmDots .swiper .swiper-wrapper {
    margin-bottom: 30px;
  }
}
.sliderBtmDots .swiper .swiper-pagination {
  bottom: 0px;
}
.sliderBtmDots .swiper .swiper-pagination .swiper-pagination-bullet {
  background: var(--navy-blue);
  opacity: 0.4;
  width: 8px;
  height: 8px;
}
.sliderBtmDots
  .swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.sliderBtmDotsText .sliderCont {
  position: absolute;
  bottom: -50px;
  margin-bottom: 0 !important;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
}
.sliderBtmDots.sliderBtmDotsText .swiper .swiper-wrapper {
  margin-bottom: 80px;
}

.sliderArrow .swiper-button-next,
.sliderArrow .swiper-button-prev {
  border: 1px solid rgba(240, 240, 240, 0.3);
  border-radius: 7px;
  width: 40px;
  height: 40px;
}
.sliderArrow .swiper-button-next:after,
.sliderArrow .swiper-button-prev:after {
  content: "" !important;
  width: 15px;
  height: 15px;
  border-top: 2px solid #292929;
  border-right: 2px solid #292929;
  transform: rotate(45deg);
  position: absolute;
  left: 9px;
}
.sliderArrow .swiper-button-prev:after {
  transform: rotate(-135deg);
  left: 15px;
}

.artistBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: stretch;
  height: 400px;
}

.artistBanner:first-child span:first-child {
  width: 100%;
}
.artistBanner .artistBannerCont {
  position: absolute;
  left: 50%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, 0);
  z-index: 2;
}

.OverviewBlock
  > .MuiBox-root:nth-child(1)
  > .gradentOverlay:nth-child(1)::before {
  display: none;
}

.artistPageCont {
  padding: 30px 0;
}
.highlighted_NftsComponentBlock button {
  width: 100%;
}

.cardItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  overflow: hidden;
}
.cardItems .cardimageBlock {
  max-height: 340px;
  overflow: hidden;
}
@media screen and (min-width: 1280px) {
  .cardItems .cardimageBlock {
    min-height: 340px;
  }
}
.cardItems .cardimageBlock .cardImg {
  transition: all 0.5s;
}
.cardItems .cardimageBlock:hover .cardImg {
  transform: scale(1.1);
}
.cardItems .cardItemDetails {
  background: #222222;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  flex-direction: column;
  padding: 32px 16px 32px 16px;
  height: 100%;
}

.cardItems .cardItemDetails h2 {
  font-family: "MercuryDisplay";
  font-weight: 325;
  font-size: 25px;
  color: #ffffff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 7px !important;
}
@media screen and (min-width: 1280px) {
  .cardItems .cardItemDetails h2 {
    font-size: 25px !important;
  }
}
.cardItems .cardItemDetails .discription {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: rgba(255, 255, 255, 0.5);
}
.cardItems .cardItemDetails .priceBlock {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cardItems .cardItemDetails .priceBlock p {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.cardItems .cardItemDetails .priceBlock p span {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 150% */
  margin-left: 5px;
  letter-spacing: 0.05em;

  color: #ffffff;

  opacity: 0.5;
}
.cardItems .cardItemDetails button {
  margin-top: 15px;
  transition: all 0.5s;
}
.cardItems .cardItemDetails button:hover {
  border: 1px solid rgba(66, 0, 96, 0.4);

  background: linear-gradient(0deg, rgba(66, 0, 96, 0.4), rgba(66, 0, 96, 0.4)),
    linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
}

.secondary-btn {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  text-align: center;
  letter-spacing: -0.1px !important;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .headerBlock header:only-child .mobileMenu {
    padding-top: 12px;
  }
}
@media screen and (max-width: 480px) {
  .headerBlock header:only-child .mobileMenu {
    padding-top: 14px;
  }
}
@media screen and (max-width: 380px) {
}

.unblur {
  animation: unblur 1s linear;
}

@keyframes unblur {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}

@media screen and (max-width: 576px) {
  .page-content-wrapper {
    /* height: calc(100vh - 10px); */
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden auto;
  }
}
@media screen and (max-width: 575px) {
  .filter .search-input-box > div {
    width: 100%;
  }
  #filter-select-dropdown {
    padding: 10px 0px;
  }
}
.home_theme_background {
  background: var(--bid-text-status) !important;
}

.home_theme_title {
  /* font-family: 'Mercury Display' !important;
  font-style: normal;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 75px !important;
  color: var(--black) !important; */
}

.home_theme_title-view-all {
  /* font-family: 'Mercury Display' !important;
  font-style: normal;
  font-weight: 325 !important;
  line-height: 75px !important;
  color: var(--black) !important;
  font-size: 48px !important; */
}

.home_theme_button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px !important;

  width: 159px !important;
  height: 45px !important;

  background: var(--navy-blue) !important;
  border-radius: 5px !important;
  color: var(--bid-text-status) !important;
}

/* .home_theme_banner_title {
  font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 325 !important;
  font-size: 48px !important;
  line-height: 75px !important;
  color: var(--black) !important;
} */

.home_theme_title {
  /* font-family: 'Mercury Display';
  font-style: normal;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 75px !important;
  color: var(--black) !important; */
}

.home_theme_sub_title {
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px;
  /* text-align: center; */
  color: var(--black);
}

.home_theme_desc {
  font-family: "BentonSans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  text-align: left;
  letter-spacing: -0.1px;
  color: var(--black);
}

.home_theme_button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px !important;

  width: 159px !important;
  height: 45px !important;

  background: var(--navy-blue) !important;
  border-radius: 5px !important;
  color: var(--bid-text-status) !important;
}
.mr-top-20 {
  margin-top: 20px;
}
.MuiAutocomplete-popper {
  border: 1px solid #bdbdbd;
  border-top: 0;
  -webkit-flex-basis: 45.833333%;
  -ms-flex-preferred-size: 45.833333%;
  flex-basis: 200px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  min-width: 200.75px;
  border-radius: 4px;
}
.menu-lable {
  padding: 8px 12px !important;
  width: calc(100% + 10px);
  margin: 0 -9px;
}
.menu-lable:hover {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

h1,
.artistBanner h1 {
  font-family: "Mercury Display";
  font-style: normal;
  font-weight: 325;
  font-size: 48px;
  line-height: 58px;
  color: var(--black);
}

/* h3{
  font-family: "MercuryTextG1" !important;
  font-style: normal;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 75px !important;
  letter-spacing: 0.0025em !important;
  color:var(--black);
} */
h3 {
  font-family: "Mercury Display" !important;
  font-style: normal !important;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 75px !important;
  color: var(--black);
}
.homePage h3 {
  margin-bottom: 20px;
  color: black;
}

.search-input-box {
  position: relative;
}
/* responsive */

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1439px) {
  .homePage h3 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1365px) {
}
@media screen and (max-width: 1279px) {
  h1,
  .artistBanner h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .artistBanner {
    padding: 20px 40px !important;
    gap: 18px !important;
  }
  .artistTitle h1,
  .artistTitle p {
    text-align: center;
  }
  .homePage h3 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1065px) {
  .grid-100 .MuiBox-root > span {
    max-height: 450px;
  }
}
@media screen and (max-width: 767px) {
  h1,
  .artistBanner h1 {
    font-size: 36px;
    line-height: 44px;
  }
  .featureArtistSubtitle {
    margin-top: 30px;
  }
  .artistBanner {
    padding: 20px 15px !important;
    gap: 18px !important;
  }
  .homePage h3 {
    font-size: 25px !important;
    line-height: 30px !important;
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 580px) {
  h1,
  .artistBanner h1 {
    font-size: 30px;
    line-height: 38px;
  }
  .grid-100 .MuiBox-root > span {
    /* min-height: 250px; */
  }
}

.headerBlock {
  position: fixed;
  width: calc(100% - 14px);
  top: 0;
  left: 0;
  z-index: 9;
}

@media screen and (max-width: 580px) {
  .headerBlock {
    width: 100%;
  }
}

.search-open .search-block {
  transform: scale(1) !important;
}

.artist-mobile-bar .qsearch-block {
  transform: scale(1) !important;
}

@media screen and (min-width: 768px) {
  .search-block .mobile-view-label-icon-block {
    display: none;
  }
  .show-mobile-view-767 {
    display: none !important;
  }
}
@media screen and (max-width: 1023px) {
  .menunav > li {
    margin-left: 8px;
  }
}
@media screen and (max-width: 991px) {
  .footer {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .menunav li .navLink {
    width: 100%;
    justify-content: space-between;
  }
}
@media screen and (min-width: 991px) {
  .btm-footer .footer-container {
    display: flex;
    justify-content: space-between;
    padding: 0 60px 0 60px;
    align-items: center;
    margin-top: 10px;
  }
}
.profileBlock.menuclicked .profileList {
  height: 225px;
}
