@media screen and (min-width:800px ) {
  .mob-view-show{
    display: none !important;
  }
}
@media screen and (max-width:800px ) {
  .mob-view-hide{
    display: none !important;
  }
}
@media screen and (min-width:576px ) {
  .mob-view-menu-nav{
    display: none;
  }
}
@media screen and (max-width:575px ) {
  .headerBlock.menu-view .notificationCom{
    z-index: 0;
  }
  .headerBlock.menu-view .profileList{z-index: 0;}
  .menunav li ol{
    display: none;
  }
  .mob-view-menu-nav{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    padding: 10px;
    transform: scale(0);
    background-color: #fff;
  }
  .menu-view  .mob-view-menu-nav{
    transform: scale(1);
  }
  .mob-view-menu-nav .mobile-view-label-icon-block{
    padding: 10px 0;
  }
  .mob-view-menu-nav .mobile-view-label-icon-block p {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .mob-view-menu-nav .mobile-view-label-icon-block p span {
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
  }
  .mob-view-menu-nav .mobile-view-label-icon-block p span svg {
      width: 15px;
      height: 15px;
  }
  .mob-view-menu-nav ul{
    padding:0;
    margin:20px 0 0 0;
    height: calc(100vh - 80px);
    overflow: hidden auto;
  }
  .mob-view-menu-nav ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--black);
    font-family: 'MercuryDisplay';
    font-style: normal;
    font-weight: 325;
    font-size: 16px;
    line-height: 19px;
    min-height: 20px;
  }
  .mob-view-menu-nav ul li span{
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-top: 10px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
 
  .mob-view-menu-nav ul li p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 19px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    padding: 10px 0;
  }
  .mob-view-menu-nav ul li.artistios{
    line-height: unset!important;
    min-height: 40px!important; 

  }
  .mob-view-menu-nav ul li.artistios p{
    line-height: unset!important;
    padding-top: 7px!important;
    padding-bottom: 0!important;
}
}